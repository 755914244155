<template>
    <v-container fill-height>
        <v-layout justify-center wrap >
            <v-flex md12 >
                <material-card
                        :title="title"
                        text_color="#FFFFFFDD">
                    <v-data-table :headers="headers"
                                  :items="products"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>

                        <template slot="headers" slot-scope="props">

                            <tr>
                                <th v-for="header in props.headers"
                                    :key="header.value"
                                    v-tooltip="header.alt || header.text">

                                    <v-icon v-if="header.sortable" small>mdi-menu-up</v-icon>
                                    <span v-if="!header.icon">{{ header.text }}</span>
                                    <v-icon v-else>{{ header.icon }}</v-icon>

                                </th>

                            </tr>
                        </template>

                        <template slot="items" slot-scope="props">

                            <td  v-for="(header, key, index) in headers" class="elf">


                                <v-layout v-if="!!header.custom">

                                    <v-layout v-if="header.custom === 'image'">
                                        <v-img v-bind:src="$store.state.backend_url + props.item[header.value]"
                                               height="40"
                                               width="40"
                                               alt=""

                                        ></v-img>
                                    </v-layout>

                                    <v-layout v-if="header.value === 'actions'">

                                        <v-btn small rounded text fab
                                               v-tooltip="'Edit Product'">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>

                                        <v-btn small rounded text fab
                                               v-tooltip="'Delete Product'">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>

                                    </v-layout>
                                </v-layout>

                                <v-layout v-else>
                                    <span class="text-truncate uk-width-small">
                                        {{ seekValue(props.item, header.value) }}
                                    </span>
                                </v-layout>
                            </td>

                        </template>

                    </v-data-table>


                </material-card>
            </v-flex>
        </v-layout>

    </v-container>

</template>

<script>
    export default {
        name: "products_to_source",
        data: () => ({
            title: "Requested Products",
            products: [],
            headers: [
                {
                    text: 'Requesting',
                    value: 'requested_by.username',
                    align: 'center'
                },
                {
                    text: 'Sourcer',
                    value: 'sourcer.username',
                    align: 'center'
                },
                {
                    text: 'Image',
                    value: 'image',
                    align: 'center',
                    custom: 'image',
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center'
                },
                {
                    text: 'MOQ',
                    value: 'moq',
                    align: 'center'
                },
                {
                    text: 'CBM',
                    value: 'cbm',
                    align: 'center'
                },
                {
                    text: 'Target Price',
                    value: 'target_price',
                    align: 'center'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    custom: true,
                    align: 'center'
                }
            ],
            loading:true
        }),
        created () {
            this.$http.get('/admins/products/src/')
                .then((response)=>{
                    console.log(response.data);
                    this.products = response.data.results;
                    this.loading = false;
                })
                .catch((error)=>{
                    console.log(error);
                    this.loading = false;
                });
        },
        methods: {
            seekValue(item, value) {
                if (value.includes('.')) {
                    let map = value.split('.');
                    let res = item;
                    for (let pos of map)
                        res = res[pos] ? res[pos] : res;
                    return res;
                } else return item[value];


            }
        }
    }
</script>

<style scoped>

</style>
